import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueWechatTitle from 'vue-wechat-title' //可以动态修改浏览器标题的插件
import '@/assets/css/border.css'
import '@/assets/css/reset.css'
import {_deepCopyFn, getUrlOptions} from '@/util/utils.js'
import { Empty } from 'vant';
import { Loading } from 'vant';
import { Button } from 'vant';
import 'vant/lib/index.css';

Vue.config.productionTip = false
Vue.prototype.$deepCopyFn = _deepCopyFn // 深克隆
Vue.prototype.$getUrlOptions = getUrlOptions // 深克隆
Vue.use(VueWechatTitle);
Vue.use(Empty);
Vue.use(Loading);
Vue.use(Button);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
