import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home/Home.vue'
import ShareView from '@/views/share/index.vue'
import NoPage from '@/views/nopage/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '天星健康'
    }
  },
  {
    path: '/share',
    name: 'share',
    component: ShareView,
    meta: {
      title: '检测报告'
    }
  },
  {
    path: '*',
    name: 'NoPage',
    component: NoPage,
    meta: {
      title: '页面不存在'
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
